<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-layout align-center px-2 row>
				<w-search-input clearable :label="$t('actions.search')" prepend-inner-icon="search" :value="search" @input="launchSearch($event)" />
				<v-spacer />
				<w-select
					v-model="selectedHeaders"
					clearable
					:items="applications"
					item-text="title"
					item-value="id"
					:label="$tc('sa.applications.applications', 2)"
					multiple
				/>
			</v-layout>
		</v-flex>
		<v-flex scroll-y>
			<w-matrix
				:headers="headers"
				:loading="loadings > 0"
				:no-data-text="
					loadings > 0
						? $t('loading_progress')
						: search
						? $t('sa.applications.no_accounting_firms_for_search', { search: search })
						: $t('sa.applications.no_active_accounting_firms')
				"
				:pagination="pagination"
				:total-items="pagination.totalItems"
				:value="rows"
				@update:pagination="syncPagination($event)"
			>
				<template v-slot:items="{ row }">
					<td class="text-xs-left">
						<v-layout align-center justify-start row>
							<v-flex shrink v-text="row.text" />
						</v-layout>
					</td>
					<td v-for="application in filteredApplications" :key="application.id" class="text-xs-center">
						<w-layout align-center column justify-center>
							<v-checkbox
								color="primary"
								hide-details
								readonly
								style="width: 24px"
								:input-value="row.values[application.id] ?? false"
								@click.native="onClick(row, application)"
							/>
						</w-layout>
					</td>
				</template>
			</w-matrix>
		</v-flex>
		<ApplicationRemovalDialog v-model="applicationRemovalDialog" :api-call="applicationRemovalApiCall" :application="selectedApplication" />
	</v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'SubscriptionsList',
	components: {
		ApplicationRemovalDialog: () => ({
			component: import('@/components/Applications/ApplicationRemovalDialog')
		})
	},
	mixins: [SuperAdminModuleGuard],
	data() {
		return {
			accountingFirms: [],
			applications: [],
			applicationRemovalAccountingFirmId: null,
			applicationRemovalDialog: false,
			loadings: 0,
			pagination: {
				rowsPerPage: 10,
				page: 1,
				totalItems: 0
			},
			search: null,
			searchTimeout: null,
			selectedApplication: null,
			selectedHeaders: []
		}
	},
	computed: {
		applicationRemovalApiCall: function () {
			return function () {
				return this.service.deleteAccountingFirmApplication(this.applicationRemovalAccountingFirmId, this.selectedApplication.id).then(() => {
					const accountingFirm = this.accountingFirms.find(({ id }) => id == this.applicationRemovalAccountingFirmId)
					if (accountingFirm) {
						const index = accountingFirm.apps.findIndex(({ id }) => id == this.selectedApplication.id)
						if (index >= 0) {
							accountingFirm.apps.splice(index, 1)
						}
					}
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.applications.application_unassigned'))
				})
			}.bind(this)
		},
		filteredApplications: function () {
			let result = this.applications

			if (this.selectedHeaders.length > 0) {
				const applicationIds = this.selectedHeaders
				result = this.applications.filter(application => applicationIds.includes(application.id))
			}

			return result
		},
		headers: function () {
			let result = [
				{
					text: this.$t('sa.accounting_firm')
				}
			]
			this.filteredApplications.forEach(application => {
				result.push({
					id: application.id,
					text: application.title
				})
			})
			return result
		},
		rows: function () {
			return this.accountingFirms.map(accountingFirm => {
				const result = {
					id: accountingFirm.id,
					text: accountingFirm.name,
					values: []
				}
				accountingFirm.apps.forEach(application => {
					result.values[application.id] = true
				})

				return result
			})
		}
	},
	mounted: function () {
		this.loadAccountingFirms()
		this.loadApplications()
	},
	methods: {
		launchSearch: function (search) {
			this.search = search

			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout)
				this.searchTimeout = null
			}

			this.searchTimeout = setTimeout(
				function () {
					const query = {
						page: 1
					}
					this.loadAccountingFirms(query)
				}.bind(this),
				1000
			)
		},
		loadAccountingFirms: function (query = {}) {
			this.loadings++

			if (!query.page) {
				query.page = this.pagination.page
			}
			if (!query.perPage) {
				query.perPage = this.pagination.rowsPerPage
			}
			if (this.search) {
				query.search = this.search
			}

			this.service
				.listAccountingFirms(query)
				.then(({ data: accountingFirms, pagination }) => {
					this.pagination.page = pagination.currentPage
					this.pagination.rowsPerPage = pagination.perPage
					this.pagination.totalItems = pagination.total

					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.loadings--
				})
		},
		loadApplications: function () {
			this.loadings++
			this.service
				.listApplications({ can_be_whitelabelled: false, is_active: true, params_accounting_firm: null })
				.then(({ data: applications }) => {
					this.applications = applications
				})
				.finally(() => {
					this.loadings--
				})
		},
		onClick: function (row, application) {
			if (!row.values[application.id]) {
				this.loadings++
				this.service
					.createAccountingFirmApplication(row.id, application.id)
					.then(() => {
						const accountingFirm = this.accountingFirms.find(({ id }) => id == row.id)
						if (accountingFirm) {
							accountingFirm.apps.push(application)
						}
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.applications.application_assigned'))
					})
					.finally(() => {
						this.loadings--
					})
			} else {
				this.selectedApplication = this.applications.find(({ id }) => id == application.id)
				this.applicationRemovalAccountingFirmId = row.id
				this.applicationRemovalDialog = true
			}
		},
		syncPagination: function (pagination) {
			this.pagination.page = pagination.page
			this.pagination.rowsPerPage = pagination.rowsPerPage

			this.loadAccountingFirms()
		}
	}
}
</script>
